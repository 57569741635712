import { setAmplitudeUser } from "@/analytics/Amplitude.ts";
import { setSentryUser } from "@/base/Sentry.ts";
import Dispatcher from "@/base/classes/Dispatcher.ts";
import { setCannyUser } from "@/feedback/CannyIOAfterBoot.ts";
import type User from "../interfaces/User.ts";

/**
 * Dispatches multiple methods after the user has been authenticated.
 */
class AuthenticatedDispatcher extends Dispatcher {
  public constructor(authUser: User) {
    super();

    /**
     * Set the authenticated user in sentry.
     */
    this.addDispatcherMethod({
      callable: () => {
        setSentryUser({
          email: authUser.email,
          id: authUser.id,
          username: authUser.full_name,
        });
      },
    });

    this.addDispatcherMethod({
      callable: () => {
        // Do something else
        setAmplitudeUser(authUser);
      },
    });

    this.addDispatcherMethod({
      callable: () => {
        // Do something else
        setCannyUser(authUser);
      },
    });
  }
}

export default AuthenticatedDispatcher;
