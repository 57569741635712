import useAuthenticationStore from "@/auth/stores/AuthenticationStore.ts";
import * as Sentry from "@sentry/vue";
import type { AxiosResponse } from "axios";

const xAppVersionHeaderKey = "x-app-version";

/**
 * Intercepts the response and checks if the frontend client version matches the backend client version.
 * @param response caught axios response
 * @returns axios response
 */
export default async function versionMismatchInterceptor(response: AxiosResponse): Promise<AxiosResponse> {
  const { url } = response.config;

  const frontendClientVersion = __APP_VERSION__;

  if (typeof frontendClientVersion !== "string") {
    Sentry.captureMessage(`The frontend client version is not a string. Value is: ${frontendClientVersion}`, {
      level: "fatal",
    });
  }

  const hasXAppVersionHeader = xAppVersionHeaderKey in response.headers;

  if (!hasXAppVersionHeader) {
    Sentry.captureMessage(`The response did not return the ${xAppVersionHeaderKey} header. URL: ${url}`, {
      level: "warning",
    });

    return response;
  }

  const backendClientVersion = response.headers[xAppVersionHeaderKey];

  if (
    backendClientVersion !== undefined &&
    frontendClientVersion !== undefined &&
    frontendClientVersion !== backendClientVersion
  ) {
    const store = useAuthenticationStore();

    store.versionMismatchDetected = true;
  }

  return response;
}
