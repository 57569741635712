import HttpClientErrorDataClass from "@/http/classes/HttpClientErrorDataClass.ts";
import CastToStatusCode from "@/http/functions/CastToStatusCode.ts";
import type { AxiosError } from "axios";
import type { NavigationFailure, RouteLocationRaw, Router } from "vue-router";

/**
 * Create route location for the error page
 * @param statusCode the status code
 * @param errorMessage the error message
 * @param error the error
 * @param isBorked whether the application is borked and the user needs to logout.
 * @returns the route location
 */
function createRouteLocation(
  statusCode: number | undefined,
  errorMessage: string | undefined,
  error: string | undefined = undefined,
  isBorked: boolean = false
): RouteLocationRaw {
  return {
    name: "error",
    query: {
      statusCode,
      errorMessage,
      error,
      isBorked: isBorked ? "true" : "false",
    },
  };
}

/**
 * Get the route location for the error page
 * @param error the axios error object
 * @returns the route location
 */
function getRouteLocationFromAxiosError(error: AxiosError): RouteLocationRaw {
  /**
   * @todo
   * add class which handles entire axios error that includes response error data and status code.
   * This is so we can encapsulate error management
   */

  /** Axios error response data */
  let errorData;
  try {
    errorData = HttpClientErrorDataClass.new(error);
  } catch {
    errorData = undefined;
  }

  /** Get valid status code */
  const status = CastToStatusCode(error.response?.status);

  return createRouteLocation(status, errorData?.responseErrorMessage);
}

/**
 * Navigate to the error route
 * @param router the vue router
 * @param errorRouteLocation the error route location
 * @returns router push promise
 */
async function navigateToErrorRoute(
  router: Router,
  errorRouteLocation: RouteLocationRaw
): Promise<NavigationFailure | undefined> {
  const res = await router.push(errorRouteLocation);

  return res ?? undefined;
}

/**
 * Navigate to the error page
 * @param router the vue router
 * @param statusCode the status code
 * @param errorMessage the error message
 * @returns router push promise
 */
async function navigateToErrorPage(
  router: Router,
  statusCode: number | undefined,
  errorMessage: string | undefined
): Promise<NavigationFailure | undefined> {
  const route = createRouteLocation(statusCode, errorMessage);

  const res = await navigateToErrorRoute(router, route);

  return res;
}

export { createRouteLocation, getRouteLocationFromAxiosError, navigateToErrorPage };

/**
 * Navigate to error page from axios error
 * @param error the axios error object
 * @param router the vue router
 * @returns router push promise
 */
export default async function NavigateToErrorPageFromAxiosError(
  error: AxiosError,
  router: Router
): Promise<NavigationFailure | undefined> {
  const route = getRouteLocationFromAxiosError(error);

  const res = await navigateToErrorRoute(router, route);

  return res;
}
