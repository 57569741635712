import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { createSentryPiniaPlugin } from "@sentry/vue";

import BaseApp from "@/base/BaseApp.vue";
import { baseAppVariables } from "@/base/BaseAppVariables.ts";
import newRouter from "@/base/router/Router.ts";
import bootDayJs from "@/date/functions/Day.ts";
import bootCannyIO from "@/feedback/CannyIO.js";

import HttpClient from "@/http/HttpClient.ts";
import Vueform, {
  /** Wysiwyg */
  EditorElement,
  EditorWrapper,
  ElementAddon,
  ElementDescription,
  ElementError,
  ElementInfo,
  ElementLabel,
  ElementLabelFloating,
  // Essential element components
  ElementLayout,
  ElementLayoutInline,
  ElementLoader,
  ElementMessage,
  ElementRequired,
  ElementText,
  // Essential form components
  FormErrors,
  FormMessages,
} from "@vueform/vueform/core";
import Vapor from "laravel-vapor";
import { createPinia } from "pinia";
import { createApp } from "vue";
import VueApexCharts from "vue3-apexcharts";
import vueformConfig from "../../../vueform.config.ts";
import bootAmplitude from "../analytics/Amplitude.ts";
import bootSentry from "./Sentry.ts";
import bootGlobalErrorHandler from "./boot/BootGlobalErrorHandler.ts";

import FloatingVue from "floating-vue";

const app = createApp(BaseApp);

app.component("FontAwesomeIcon", FontAwesomeIcon);

const router = newRouter("web");
app.use(router);

bootSentry(router);

// Boot after Sentry to capture errors. Boot after Pinia to use the store.
bootGlobalErrorHandler();

const pinia = createPinia();

pinia.use(createSentryPiniaPlugin());

app.use(pinia);

bootAmplitude();

bootDayJs();

/* istanbul ignore if -- @preserve CannyIO causes tests to fail */
if (import.meta.env["VITEST_MODE"] === undefined) {
  bootCannyIO();
}

Vapor.withBaseAssetUrl(import.meta.env["VITE_VAPOR_ASSET_URL"]);

app.use(VueApexCharts);

app.use(FloatingVue);

app.use(Vueform, {
  ...vueformConfig,
  components: {
    // Essential form components
    FormErrors,
    FormMessages,

    // Essential element components
    ElementLayout,
    ElementLayoutInline,
    ElementLoader,
    ElementLabelFloating,
    ElementLabel,
    ElementInfo,
    ElementDescription,
    ElementError,
    ElementMessage,
    ElementText,
    ElementAddon,
    ElementRequired,

    EditorElement,
    EditorWrapper,
  },
});

app.mount("#app");

window.baseAppVariables = baseAppVariables;
window.httpClient = HttpClient;
