import useAuthenticationStore from "@/auth/stores/AuthenticationStore.ts";
import errorHandler from "@/base/functions/errors/ErrorHandler.ts";
import { UserCurrentCompanySitesPermissionsSchema } from "../schemas/PermissionsSchema.ts";
import canPerformPermission from "./CanPerformPermission.ts";

/**
 * Check if the authenticated user can perform a site permission.
 * @param siteId Site ID to check
 * @param permission Permission to check
 * @returns boolean Can the user perform the permission
 */
function canPerformSitePermission(siteId: number, permission: string): boolean {
  const authenticationStore = useAuthenticationStore();
  const { isSuperAdmin } = authenticationStore;

  const parseRes = UserCurrentCompanySitesPermissionsSchema.safeParse(authenticationStore.sitePermissions);

  if (!parseRes.success) {
    errorHandler(new Error("Invalid site permissions data"));

    // Super admins can perform any permission.
    // Allow them to continue even if the data is invalid.
    return isSuperAdmin;
  }

  // Super admins can perform any permission.
  if (isSuperAdmin) return isSuperAdmin;

  const userCurrentCompanyAllSitesPermissions = parseRes.data;

  // Get the specific site object permissions.
  const siteObject = userCurrentCompanyAllSitesPermissions.find((site) => site.site_id === siteId);

  // If the user does not have permissions for the site, they cannot perform the permission.
  if (!siteObject) {
    return false;
  }

  // Check if the user can perform the permission.
  return canPerformPermission(siteObject.permissions, permission);
}

export default canPerformSitePermission;
