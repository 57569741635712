import type User from "@/auth/interfaces/User";
import * as amplitude from "@amplitude/analytics-browser";

const setAmplitudeUser = (user: User): void => {
  const identifyEvent = new amplitude.Identify();

  identifyEvent.set("id", user.id);
  identifyEvent.set("username", user.full_name);
  identifyEvent.set("email", user.email);
  identifyEvent.set("id", user.id);
  identifyEvent.set("is_super_admin", user.is_super_admin);
  identifyEvent.set("is_impersonated", user.is_impersonated);
  identifyEvent.set("current_company_id", user.current_company_id ?? "None");
  identifyEvent.set("current_company_name", user.current_company_name ?? "None");
  identifyEvent.set("current_company_role", user.current_company_role ?? "None");
  identifyEvent.set("current_site_id", user.current_site_id ?? "None");
  identifyEvent.set("current_site_name", user.current_site_name ?? "None");
  identifyEvent.set("environment", import.meta.env["VITE_APP_ENV"]);
  /* istanbul ignore if -- @preserve */
  if (typeof __APP_VERSION__ === "string") {
    identifyEvent.set("version", __APP_VERSION__);
  }
  amplitude.setUserId(user.email);

  amplitude.setGroup("company", user.current_company_name ?? "None");
  amplitude.setGroup("site", user.current_site_name ?? "None");

  // Use methods in the following sections to update the Identify object
  amplitude.identify(identifyEvent);
};

const bootAmplitude = (): void => {
  amplitude.init(import.meta.env["VITE_AMPLITUDE_API_KEY"], {
    instanceName: import.meta.env["VITE_APP_NAME"],
    autocapture: true,
  });
};

export { setAmplitudeUser };

export default bootAmplitude;
