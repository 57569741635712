import AnalyticEventsEnum from "@/analytics/enums/AnalyticEventsEnum.ts";
import analyticTrack from "@/analytics/functions/AnalyticEvent.ts";
import type { AxiosError } from "axios";
import { HttpStatusCode, isAxiosError } from "axios";

/**
 * Catches all global axios errors
 * @param error caught axios error
 * @returns resolved promise
 */
export default async function globalAxiosCatchInterceptor(error: AxiosError | Error | unknown): Promise<Error> {
  if (isAxiosError(error)) {
    /**
     * Redirect the user to login screen if error is a response
     * and has unauthorized status code
     */
    if (
      error.response !== undefined &&
      [HttpStatusCode.Unauthorized, 419].includes(error.response.status as HttpStatusCode) &&
      !window.location.pathname.startsWith("/login")
    ) {
      if (error.response.status === 419) {
        analyticTrack(AnalyticEventsEnum.pageExpired);
      }

      window.location.href = "/login";
    }
  }
  return Promise.reject(error);
}
